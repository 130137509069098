import { t } from "@prmf/site-router";
import { useLocation } from "@remix-run/react";
import { Autoplay, EffectFade } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { twMerge } from "tailwind-merge";
import BgImage from "./BgImage";

import "swiper/css";
import "swiper/css/effect-fade";

type Props = React.PropsWithChildren & {
  title?: string;
  image?: string | string[];
  bgColor?: string;
  className?: string;
  reverse?: true;
};

export default function Header(props: Props) {
  const { pathname } = useLocation();
  const backgroundColor = props.bgColor || "black";
  const images = Array.isArray(props.image)
    ? props.image
    : [props.image || t(pathname, "image")];
  const title = props.title || t(pathname, "title");
  return (
    <header className="grid font-light md:grid-cols-2">
      <Swiper
        className="w-full"
        modules={[Autoplay, EffectFade]}
        effect="fade"
        autoplay={{ delay: 5000 }}
        loop
      >
        {images.map((img) => (
          <SwiperSlide key={img}>
            <BgImage
              key={img}
              src={img}
              className="aspect-[4/3] max-h-[420px]"
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div
        className={twMerge(
          "max-md:container",
          props.reverse && "md:order-first",
          ["black", "#000000", "#000"].includes(backgroundColor) &&
            "text-white",
          props.className,
        )}
        style={{ backgroundColor }}
      >
        <div
          className={twMerge(
            "relative space-y-4 py-10 md:mt-10 md:px-10",
            props.reverse
              ? "md:half-container md:ml-auto md:pl-0 md:pr-10"
              : "md:max-w-prose",
          )}
        >
          <h2
            className={twMerge(
              "max-w-md text-white",
              Number(title?.length) > 60
                ? "text-2xl font-light"
                : "text-4xl font-thin",
            )}
          >
            {title}
          </h2>
          {props.children}
        </div>
      </div>
    </header>
  );
}
